import React from 'react'
import { NavLink } from 'react-router-dom'
import { contactRoute, downloadRoute } from '../constants/const'
import Carousel from 'react-multi-carousel';
import { exemple_image, hero_shape, shape_1, shape_2, shape_3 } from '../constants/files'

const Home = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1400 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1400, min: 992 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 992, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1
        }
    };

    return (
        <>
            {/* Hero */}
            <section className="hero-equal-height ptb-100 gradient-overlay hero-bg pb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-8 z-3">
                            <div className="hero-content-left text-black my-lg-0 my-md-5 my-sm-4 my-5">
                                <h1 className="text-black pb-md-5 pb-3">Créez des sondages interactifs et des quizz en toute simplicité !</h1>
                                <div className="mt-4 row flex-wrap">
                                    <div className='col-auto mb-5'>
                                        <NavLink to={downloadRoute} className="btn-fill ">Download Now</NavLink>
                                    </div>
                                    <div className='col-auto '>
                                        <NavLink to={contactRoute} className="btn-not-fill">Contact with us</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <div className="video-app-wrap ms-auto mt--120 d-none d-sm-none d-md-block d-lg-block">
                                <div className="iphone-mask">
                                    {/* <img src={hero_app_interface} alt="app" className="img-fluid mask-img"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-bottom overflow-hidden">
                    <img src={hero_shape} alt="shape" className="bottom-shape"/>
                </div>
            </section>


            {/* Why us */}
            <section id='why-us' className=' py-5 ' style={{minHeight:'80vh'}}>
                <div className=' container my-5'>
                    <div className="row justify-content-center align-items-center">
                        <div className=' col-md-6 col-12'>
                            <div className="circle-icon mb-3 d-flex justify-content-center align-items-center">
                                <span className="color-primary fa fa-icons fa-4x"></span>
                            </div>
                        </div>
                        <div className=' col-md-6 col-12 text-md-end text-center'>
                            <h3 className=' fw-bold mb-3'>Lorem, ipsum dolor</h3>
                            <p className=' text-black text-opacity-50'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque odio, facilis animi pariatur vero sapiente maxime harum quibusdam. Deleniti, excepturi. Porro ipsa eaque animi voluptates, quia quo quaerat ratione alias facere iste, voluptatibus vero minus autem nulla. Corporis, recusandae explicabo?</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* CTA */}
            <section id="download" className="gradient-nav position-relative">
                <div className="container">
                    <div className="row justify-content-around align-items-end">
                        <div className="col-md-8 col-12 z-3">
                            <div className="download-txt text-black ptb-100 text-center">
                                <h2 className="text-black">
                                    Ne ratez pas les prochains événements !
                                </h2>
                                <p className="text-black text-opacity-75">Souscris à notre newsletter</p>
                                <div className="row justify-content-center align-items-center mt-4">
                                    <div className=' col-lg-4 col-md-6 col-12 mb-3'>
                                        <input type='text' className=' form-control px-3 rounded-pill' placeholder='Nom' name='name' />
                                    </div>
                                    <div className=' col-lg-4 col-md-6 col-12 mb-3'>
                                        <input type='email' className=' form-control px-3 rounded-pill' placeholder='Email' name='email' />
                                    </div>
                                    <div className=' col-lg-4 col-md-6 col-12 mb-3'>
                                        <input type='submit' value={"Souscrire"} className=' bgcolor-secondary form-control border-0 text-white rounded-pill' name='send' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Calendar */}
            <section id='calendar' className=' fade-bg py-5'>
                <div className=' container position-relative my-5'>
                    <h2 className=' fw-bold'>Evenements à venir</h2>
                    <Carousel className=' py-5 z-3' responsive={responsive} infinite={false} autoPlay={false}>
                        {
                            [1,1,1,1,1,1].map((elt,index) =>(
                                <div  key={index+elt} className=' px-1'>
                                    <div className=' bg-white shadow rounded-4 p-2 translate-y'>
                                        <div className=' d-flex justify-content-center align-items-center overflow-hidden rounded-3 position-relative' style={{height:'10rem'}}>
                                            <img alt="img" src={exemple_image} className=' object-fit-cover w-100 h-100' />
                                            <span className=' position-absolute px-2 rounded-pill bg-white bg-opacity-75 text-success top-0 start-0 ms-1 mt-2'>23-07-2024</span>
                                        </div>
                                        <span className=" fw-bold mt-2 d-block">Lorem, ipsum dolor sit amet consectetur</span>
                                        <small>Porro ipsa eaque animi voluptates, quia quo quaerat ratione alias facere iste, voluptatibus vero minus autem nulla. Corporis, recusandae explicabo?</small>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                    <img alt='shape' src={shape_1} className=' position-absolute top-0 moveHorizontal mt-5'/>
                    <img alt='shape' src={shape_2} className=' position-absolute top-0 end-0 animationFramesTwo'/>
                    <img alt='shape' src={shape_3} className=' position-absolute bottom-0 moveVertical'/>
                </div>
            </section>
        </>
    )
}

export default Home
