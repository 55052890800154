import logoW from '../assets/images/mixuptoyou-white.png'
import logoB from '../assets/images/mixuptoyou-black.png'
import heroShape from '../assets/images/hero-shape.svg'
import exple from '../assets/images/galaxy-image2.jpg'
import shape1 from '../assets/images/br-shape-3.png'
import shape2 from '../assets/images/br-shape-4.png'
import shape3 from '../assets/images/br-shape-6.png'



// Logos
export const logo_w = logoW
export const logo_b = logoB


export const hero_shape = heroShape
export const exemple_image = exple
export const shape_1 = shape1
export const shape_2 = shape2
export const shape_3 = shape3