import React from 'react'

// Import styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/remixicon/remixicon.css'
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'aos/dist/aos.css'
import './components/style/style.css'


import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './components/ui/Header'
import Home from './views/Home'
import Footer from './components/ui/Footer'

function App() {
  return (
    <BrowserRouter>
      <div className=" position-relative">
        <Header/>
          <Routes>
            <Route path="/" element={<Home/>} >
            </Route>
          </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
