import React from 'react'
import { logo_b } from '../../constants/files'

const Footer = () => {
    return (
        <div className='fade-bg2'>
            <div className=' container pt-5'>
                <div className=' bgcolor-secondary p-4 d-flex justify-content-between align-items-center flex-wrap rounded-3 my-5'>
                    <h3 className='mb-0 fw-bold text-white'>Lorem, ipsum dolor sit amet consectetur </h3>
                    <div role='button' className=' bg-white px-4 py-2 fw-bold rounded-3 mt-md-0 mt-3'>Télécharger</div>
                </div>

                <div className=' row  justify-content-between align-items-start pt-4 pb-5'>
                    <div className=' col-md-6 col-12'>
                        <div className=' row justify-content-start align-items-start'>
                            <div className=' col-lg-6 col-12'>
                                <div>
                                    <div className=' d-flex align-items-center mb-3'>
                                        <img src={logo_b} alt='logo swiftech' className=' img-fluid' style={{height:"65px"}} />
                                        <h2 className=' fw-bold mb-0 mt-1 color-secondary fs-4 ms-2'>MIX UP</h2>
                                    </div>
                                    <span className=''>Lorem, ipsum dolor sit amet consectetur</span>
                                    <div className=' d-flex align-items-center mt-4'>
                                        <i className=' fa fa-phone-square fs-5 color-secondary'></i>
                                        <a className='text-black text-opacity-75 ms-2' href={"tel:"}>+237 612 345 678</a>
                                    </div>
                                    <div className=' d-flex align-items-center mt-2'>
                                        <i className=' fa fa-envelope fs-5 color-secondary'></i>
                                        <a className='text-black text-opacity-75 ms-2' href={'mailto:'}>email@gmail.com</a>
                                    </div>
                                    <div className=' d-flex align-items-center mt-2'>
                                        <i className=' fa fa-location-dot fs-5 color-secondary'></i>
                                        <a className='text-black text-opacity-75 ms-3' target='_BLANK' rel='noreferrer' href={"localisationLink"}>Douala Cameroun</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=' col-md-6 col-12 mt-md-0 mt-4'>
                        <div className=' ms-auto' style={{maxWidth:'400px'}}>
                            <h2 className=' text-uppercase fw-bold mb-3'>newsletter</h2>
                            <span>Souscrire à notre newsletter pour ne manquer aucun des événements à venir</span>
                            <input type='email' className='form-control my-3 py-2' placeholder='Adresse email' />
                            <input type='submit' className='form-control py-2 bgcolor-secondary text-white fw-bold' value='Souscrire' />
                        </div>
                    </div>
                </div>

                <div className=' mx-auto text-center pt-5 pb-5' style={{maxWidth:'600px'}}>
                    <div className=' row justify-content-center align-items-center mb-2'>
                        <a className=' col-auto' href='rs'><i className=' fs-3 color-secondary fa-brands fa-facebook'></i></a>
                        <a className=' col-auto' href='rs'><i className=' fs-3 color-secondary fa-brands fa-instagram'></i></a>
                        <a className=' col-auto' href='rs'><i className=' fs-3 color-secondary fa-brands fa-linkedin'></i></a>
                        <a className=' col-auto' href='rs'><i className=' fs-3 color-secondary fa-brands fa-youtube'></i></a>
                        <a className=' col-auto' href='rs'><i className=' fs-3 color-secondary fa-brands ri-twitter-x-line'></i></a>
                    </div>
                    <small>Copyright &copy; 2024 <strong>Mix Up To You</strong>. Tout droits reservés. </small>
                </div>
            </div>
        </div>
    )
}

export default Footer
