import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { logo_b } from '../../constants/files'

const Header = () => {

    const [bg, setBg] = useState(false)

    window.addEventListener("scroll",()=>{
        if (window.scrollY > 30) {
            setBg(true)
        } else {
            setBg(false)
        }
    })

    return (
        <>
            <header id='navId' className={'mb-3 py-2 fixed-top top-0 ' + (bg?' transparent-bg':'')}>
                <div className='container'>
                    <div className='row align-items-center justify-content-between'>
                        <NavLink to={'/'} className=' col-auto' style={{"height":"80px"}}>
                            <img src={logo_b} alt='HUTY' className=' img-fluid object-fit-contain h-100' />
                        </NavLink>
                        <div className='col-auto ms-auto d-flex justify-content-end'>
                            <a href='#cta' role='button' className='bgcolor-secondary rounded-3 px-3 py-2 text-white'>Télécharger</a>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
